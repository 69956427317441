import React from 'react'
import { Link } from 'gatsby'
import './404.css';

const NotFoundPage = () => (
  <div className="wrapper">
    <h1>Oops!</h1>
    <p>
    	This page doesn't exist. Please head back to the <Link to="/">homepage</Link>.
    </p>
  </div>
)

export default NotFoundPage
