import React, { Fragment } from "react"
import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

const social = { 
  linkedin: 'https://www.linkedin.com/in/lineileen',
  github: 'https://github.com/minteapixel',
  portfolio: 'https://lineileen.com/' 
};

export default () => (
  <Fragment>
    Hi, I'm <Styled.a href={social.linkedin}>Eileen Lin</Styled.a>!
    This is a blog where I write about my coding journey. Enjoy!
    <br />
    <Styled.a href={social.github}>GitHub</Styled.a> • <Styled.a href={social.linkedin}>LinkedIn</Styled.a> • <Styled.a href={social.portfolio}>Portfolio</Styled.a>
  </Fragment>
)
